// Copyright (C) 2021-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import Collapse from 'antd/lib/collapse';
import ItemAttribute from './object-item-attribute';
import axios from 'axios';

interface Props {
    readonly: boolean;
    collapsed: boolean;
    attributes: any[];
    values: Record<number, string>;
    changeAttribute(attrID: number, value: string): void;
    collapse(): void;
}

export function attrValuesAreEqual(next: Record<number, string>, prev: Record<number, string>): boolean {
    const prevKeys = Object.keys(prev);
    const nextKeys = Object.keys(next);
    return (
        nextKeys.length === prevKeys.length &&
        nextKeys.map((key: string): boolean => prev[+key] === next[+key]).every((value: boolean) => value)
    );
}

function attrAreTheSame(prevProps: Props, nextProps: Props): boolean {
    return (
        nextProps.readonly === prevProps.readonly &&
        nextProps.collapsed === prevProps.collapsed &&
        nextProps.attributes === prevProps.attributes &&
        attrValuesAreEqual(nextProps.values, prevProps.values)
    );
}

function ItemAttributesComponent(props: Props): JSX.Element {
    const {
        collapsed, attributes, values, readonly, changeAttribute, collapse,
    } = props;

    const [apiData, setApiData] = useState<any[]>([]);
    const [selectedObservation, setSelectedObservation] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://sims-config-test.detectpl.com/api/sims/list/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic cm9oaXRoOnJvaGl0aEBkZXRlY3Q=`,
                },
            });

            setApiData(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle specific error cases
            if (error.response) {
                console.log('Response data:', error.response.data);
                console.log('Response status:', error.response.status);
                console.log('Response headers:', error.response.headers);
            } else if (error.request) {
                console.log('No response received:', error.request);
            } else {
                console.log('Error message:', error.message);
            }
        }
    };

    const handleObservationChange = (value: string) => {
        setSelectedObservation(value);
        const recommendation = apiData.find((item) => item.observation === value)?.recommendation || '';
        const recommendationAttr = attributes.find((attr) => attr.name === 'Recommendations');
        if (recommendationAttr) {
            changeAttribute(recommendationAttr.id, recommendation);
        }
    };

    return (
        <Row>
            <Collapse
                className='cvat-objects-sidebar-state-item-collapse'
                activeKey={collapsed ? [] : ['details']}
                onChange={collapse}
            >
                <Collapse.Panel header={<Text style={{ fontSize: 10 }} type='secondary'>DETAILS</Text>} key='details'>
                    {attributes.map(
                        (attribute: any): JSX.Element => (
                            <Row
                                key={attribute.id}
                                align='middle'
                                justify='start'
                                className='cvat-object-item-attribute-wrapper'
                            >
                                <ItemAttribute
                                    readonly={readonly}
                                    attrValue={values[attribute.id]}
                                    attrInputType={attribute.inputType}
                                    attrName={attribute.name}
                                    attrID={attribute.id}
                                    attrValues={attribute.values}
                                    changeAttribute={changeAttribute}
                                    apiData={apiData}
                                    selectedObservation={selectedObservation}
                                    onObservationChange={handleObservationChange}
                                />
                            </Row>
                        ),
                    )}
                </Collapse.Panel>
            </Collapse>
        </Row>
    );
}

export default React.memo(ItemAttributesComponent, attrAreTheSame);